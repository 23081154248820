.App {
  text-align: center;
}

.dev-banner {
  width: 300px;
  position: fixed;
  top: 50px;
  left: -80px;
  text-align: center;
  letter-spacing: 1;
  color: rgb(240, 240, 240);
  transform: rotate(-45deg);
  z-index: 9999;
  font-size: 16px;
  background: rgb(238, 68, 51);
  cursor: default;
}

.dev-banner:hover {
  opacity: 0.3;
}

@media (prefers-reduced-motion: no-preference) {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.helper > button {
  top: 11px;
  right: 11px;
}

.helper [data-tour-elem=controls] {
  justify-content: center;
}